import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

service.defaults.timeoutErrorMessage = "Timeout error";

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (Boolean(config.headers["alternativeBaseURL"])) {
      config.baseURL = config.headers["alternativeBaseURL"];
    }

    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === "Timeout error") {
      return Promise.reject(new Error("Timeout error"));
    }

    const { status, data } = error.response;
    // Si no tiene un Token o está vencido, lo enviamos al formulario de Login
    // if (status === 401 && data === "Invalid Token") {
    //   console.log(data);
    //   store.dispatch("auth/logout");
    //   if (router.history.current.name !== "auth-login")
    //     router.push({ name: "auth-login" });
    //   if (store.state.auth.lang === "es") {
    //     return Promise.reject(new Error("Sesión expirada"));
    //   } else {
    //     return Promise.reject(new Error("Expired session"));
    //   }
    // }

    // if (status === 403 && data === "A token is required for authentication") {
    //   console.log(data);
    //   store.dispatch("auth/logout");
    //   if (router.history.current.name !== "auth-login")
    //     router.push({ name: "auth-login" });
    //   if (store.state.auth.lang === "es") {
    //     return Promise.reject(new Error("Sesión expirada"));
    //   } else {
    //     return Promise.reject(new Error("Expired session"));
    //   }
    // }
    // return Promise.reject(error);
    if (status === 400) {
      return Promise.reject(error);
    }
  }
);

export default service;
