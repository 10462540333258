const state = {
    categoryState: {
      document_number: '',
      provider_id: null,
      token: null,
      is_approved: false,
      is_new: false,
      name: '',
      month_prev: 0,
      month_actual: 0,
      category: {
        name: "",
        id: "",
        minimum_point: 0,
        minimum_rating: 0,
        maximum_cancelled_rate: 0,
        minimum_accepted_rate: 0
      },
      category_provider: {
        name: "",
        id: "",
        benefits: null,
        actual: {
          points: 0,
          rating: 0,
          cancelled_rate: 0,
          accepted_rate: 0
        },
        previous: {
          point: 0,
          rating: 0,
          cancelled_rate: 0,
          accepted_rate: 0
        }
      },
      color: "",
      phone_provider: "",
      benefits: {
        advancement: {
          visible: true,
          disable: false,
          request: false,
          amount: "$0",
          amount_paid: "$0",
          amount_pending: "$0",
          money_advance_id: ""
          },
        town_trucks: {
          visible: true,
          trips: 0,
          trips_available: 0,
          trips_completed: 0
        },
        amed: {
          visible: true,
        }
    },
    next_category: {
      category: "",
      id: "",
      minimum_point: 0,
      minimum_rating: 0,
      maximum_cancelled_rate: 0,
      minimum_accepted_rate: 0
    },
    is_evaluation: false
  }
}


const mutations = {
    SET_CATEGORY_STATE(state, categoryState) {
      state.categoryState = categoryState;
    }
  };
  
  const actions = {
    setCategoryState({ commit }, categoryState) {
      commit("SET_CATEGORY_STATE", categoryState);
    },
    
  };
  
  const getters = {
    categoryState: (state) => state.categoryState,
    
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };