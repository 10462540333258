import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

Vue.mixin({
  computed: {
    ES() {
      return this.$i18n.locale === "es";
    }
  }
});