<template>
  <v-app>
    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import { setVeeLocale } from "@/utils";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",

  data: () => ({
    //
  }),

  computed: {
    ...mapState(["language"]),
  },
  beforeMount() {
    this.$i18n.locale = this.language
    setVeeLocale(this.$i18n.locale);
    this.$vuetify.lang.current = this.$i18n.locale;
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Ridery web views";
      },
    },
  },
};
</script>
