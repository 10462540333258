import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import moment from "moment-timezone";
import "./assets/scss/global.scss";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "@/plugins/vee-validate";
import 'floating-vue/dist/style.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

import VueCurrencyFilter from "vue-currency-filter";
import TabCarousel from "./plugins/vue-tabs-carousel";
Vue.use(TabCarousel);

import './mixins';
import './filters';


Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
  avoidEmptyDecimals: undefined,
});

Vue.config.productionTip = false;


Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

// Prefered: as a plugin (directive + filter) + custom placeholders support
import VueMask from "v-mask";
Vue.use(VueMask);

Vue.use(require("vue-moment"));

import money from "v-money";

// register directive v-money and component <money>
Vue.use(money, { precision: 2 });

import { DatePicker } from "vue-drumroll-datetime-picker";
import "vue-drumroll-datetime-picker/dist/style.css";

Vue.component("DatePicker", DatePicker);

import axiosPlugin from './plugins/axios-plugin';

Vue.use(axiosPlugin);

import FloatingVue from 'floating-vue'

Vue.use(FloatingVue)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init()
  },
}).$mount("#app");
