// store/modules/user.js

const state = {
  notificationState: {
    token: null,
    cityId: null,
    userType: null,
    userID: null,
    cityName: null,
    fleetTypeId: null
  },
  communicationsStateCollection: { notifications: [], announcements: [], combined: [] },
  notificationPage: {
    tab: 0,
  },

  // beta
  notificationState: {
    token: null,
    cityId: null,
    userType: null,
    userID: null,
    cityName: null,
    fleetTypeId: null,
  },
  // userType = 1 usuario || userType = 2 provider
};

const mutations = {
  SET_NOTIFICATION_STATE(state, notificationState) {
    state.notificationState = notificationState;
  },
  SET_COMMUNICATIONS_STATE_COLLECTION(state, communicationsStateCollection) {
    state.communicationsStateCollection = communicationsStateCollection;
  },
  SET_NOTIFICATION_PAGE_STATE(state, notificationPage) {
    state.notificationPage = notificationPage;
  },
};

const actions = {
  setNotificationState({ commit }, notificationState) {
    commit("SET_NOTIFICATION_STATE", notificationState);
  },
  setCommunicationsStateCollection({ commit }, communicationsStateCollection) {
    commit(
      "SET_COMMUNICATIONS_STATE_COLLECTION",
      communicationsStateCollection
    );
  },
  setNotificationPageState({ commit }, notificationPage) {
    commit("SET_NOTIFICATION_PAGE_STATE", notificationPage);
  },
};

const getters = {
  notificationState: (state) => state.notificationState,
  communicationsStateCollection: (state) => state.communicationsStateCollection,
  notificationPage: (state) => state.notificationPage,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
