import { localize } from "vee-validate";

export function setVeeLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then((locale) => {
    localize(code, locale);
  });
}
export function toLowerCase(string) {
  return string.toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string ? string[0].toUpperCase() + string.slice(1) : "";
}

export function formatVzlaPhoneNumber(phone) {
  let pluzZero = "0" + phone;
  return pluzZero.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, "($1)-$2-$3-$4");
}
