import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import es from "vuetify/src/locale/es.ts";
import en from "vuetify/src/locale/en.ts";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3BD4AE",
        secondary: "#000421",
        error: "#DE2568",
        success: "#3BD4AE",
      },
      dark: {
        // secondary: "#ff0000", // red
        // background: '#ff0000',
        'v-date-picker-table': '#ff0000'
      },
    },
  },
  lang: {
    locales: { es, en },
  },
});
