import Vue from "vue";
import moment from "moment-timezone";

Vue.filter("dateFormat", (value) => {
  if (!value) return "";
  return moment(value).tz("America/Caracas").format("DD/MM/YYYY");
});

Vue.filter("dateFormatWeek", (value, twoPaymentWeek) => {
  if (!value) return "";
  const date = moment(value);

  if (twoPaymentWeek) {
    let startDate = date.clone().startOf("week");
    let endDate = date.clone().endOf("week").day(3);

    if (date.day() > 3) {
      startDate = date.clone().startOf("week").day(4);
      endDate = date.clone().endOf("week").day(6);
    }
    return `${startDate.format("DD/MM/YYYY")} - ${endDate.format(
      "DD/MM/YYYY"
    )}`;
  } else {
    return `${date.startOf("week").format("DD/MM/YYYY")} - ${date
      .endOf("week")
      .format("DD/MM/YYYY")}`;
  }
});