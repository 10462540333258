// store/modules/user.js

const state = {
  // price: "$100.00",
  // selectedPayment: 'pago-movil',
  // bottomSheet: true,
  price: "",
  selectedPayment: null,
  bottomSheet: false,
};

const mutations = {
  SET_PRICE(state, price) {
    state.price = price;
  },
  SET_SELECTED_PAYMENT(state, selectedPayment) {
    state.selectedPayment = selectedPayment;
  },
  SET_BOTTOMSHEET(state, bottomSheet) {
    state.bottomSheet = bottomSheet;
  },
};

const actions = {
  setPrice({ commit }, price) {
    commit("SET_PRICE", price);
  },
  setSelectedPayment({ commit }, selectedPayment) {
    commit("SET_SELECTED_PAYMENT", selectedPayment);
  },
  setBottomSheet({ commit }, bottomSheet) {
    commit("SET_BOTTOMSHEET", bottomSheet);
  },
};

const getters = {
  price: (state) => state.price,
  selectedPayment: (state) => state.selectedPayment,
  bottomSheet: (state) => state.bottomSheet,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
